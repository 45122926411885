export interface CustomerSettingsContextState {
	usesMarkets: boolean
	usesDualProduct: boolean
	usesDeviceName: boolean
	enabledSettings: CustomerSettingsEnum[]
}

export type CustomerSettingsProviderProps = {
	children: React.ReactNode
	customer?: string
}

export type useCustomerSettingsType = () => CustomerSettingsContextState

export enum CustomerSettingsEnum {
	USES_MARKETS = 'USES_MARKETS',
	USES_DUAL_PRODUCT = 'USES_DUAL_PRODUCT',
	USES_DEVICE_NAME = 'USES_DEVICE_NAME',
}
