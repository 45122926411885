import React, { FC } from 'react'

import { Box, BoxProps } from '@mui/material'
import Divider from '@mui/material/Divider'

interface CustomDividerProps extends BoxProps {
	className?: string
	mb?: number | string
	mt?: number | string
	transparent?: boolean
	thickness?: number
}

export const CustomDivider: FC<CustomDividerProps> = (props) => {
	const { className, transparent = false, thickness, ...otherProps } = props
	let { mb = 1, mt = 1 } = props

	if (transparent) {
		return <Box className={className} pb={mb} pt={mt}></Box>
	}

	return (
		<Box className={className} mb={mb} mt={mt} {...otherProps}>
			{!transparent && <Divider sx={{ borderBottomWidth: thickness }} />}
		</Box>
	)
}
