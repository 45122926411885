import { CustomIconFromValue } from '@app/components'
import { isMenuItemInPathname, RouteItem } from '@app/constants/routes'
import { TranslateMessage } from '@app/lib/lang'
import { Box } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import Icon from '@app/components/Icon'
import theme from '@app/lib/materialui/theme'

interface SidebarMainItemProps {
	menuItem: RouteItem
	onClick: (menuIteM: RouteItem) => void
}

export const SidebarMainItem: FC<SidebarMainItemProps> = ({
	menuItem,
	onClick,
}) => {
	const { pathname } = useRouter()
	const isSelected = isMenuItemInPathname(menuItem, pathname, true)

	const sxActive = {
		fill: 'white',
		color: 'white',
	}

	const sxListOuter = {
		color: '#87a0b2',
		fill: '#87a0b2',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: 110,
		height: 110,
		flexDirection: 'column',
		borderBottom: 'solid 1px #4e5d68',
		transition: 'fill 0.2s, color 0.2s',
		'&:hover': {
			fill: 'white',
			color: 'white',
		},
	}

	const listItem = (
		<>
			{menuItem.icon && (
				<ListItemIcon
					sx={{
						fontSize: 40,
						display: 'flex',
						justifyContent: 'center',
						...(isSelected && sxActive),
					}}
				>
					{menuItem.icon
						.toLowerCase()
						.includes('svg'.toLowerCase()) ? (
						<CustomIconFromValue icon={menuItem.icon} />
					) : (
						<Icon icon={menuItem.icon} size={46} />
					)}
				</ListItemIcon>
			)}

			{menuItem.label && (
				<Box
					sx={{
						fontSize: '12px',
						marginTop: theme.spacing(2),
						...(isSelected && sxActive),
					}}
				>
					<TranslateMessage id={menuItem.label} />
				</Box>
			)}
		</>
	)

	if (menuItem.to) {
		return (
			<Link href={menuItem.to} passHref>
				<ListItem
					selected={isSelected}
					button
					key={menuItem.id}
					onClick={() => onClick(menuItem)}
					sx={{
						...sxListOuter,
						...(isSelected && {
							backgroundImage:
								'linear-gradient(left, white, white 5%, transparent 5%, transparent 100%)',
						}),
					}}
				>
					{listItem}
				</ListItem>
			</Link>
		)
	}

	return (
		<ListItem
			selected={isSelected}
			button
			onClick={() => onClick(menuItem)}
			key={menuItem.id}
			sx={{
				...sxListOuter,
				...(isSelected && {
					backgroundImage:
						'linear-gradient(left, white, white 5%, transparent 5%, transparent 100%)',
				}),
			}}
		>
			{listItem}
		</ListItem>
	)
}
