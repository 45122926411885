import { createContext, PropsWithChildren } from 'react'
import { CustomerSettings, useCustomerSettingsQuery } from '@app/lib/graphql'
import {
	CustomerSettingsContextState,
	CustomerSettingsProviderProps,
	CustomerSettingsEnum,
} from './types'
import { getCustomerFromHost } from './utils'
import { useCustomerSettings } from './useCustomerSettings'

const initialContextState: CustomerSettingsContextState = {
	usesMarkets: false,
	usesDualProduct: false,
	usesDeviceName: false,
	enabledSettings: [],
}

export const CustomerSettingsContext = createContext(initialContextState)

export const CustomerSettingsProvider = ({
	children,
	customer: customerFromProps,
}: PropsWithChildren<CustomerSettingsProviderProps>) => {
	const customer =
		customerFromProps ?? getCustomerFromHost(window.location.hostname)

	const { data } = useCustomerSettingsQuery({
		skip: !customer,
		variables: {
			customer: customer,
		},
		fetchPolicy: 'cache-first',
	})

	const customerSettings = data?.customerSettings

	const contextValue: CustomerSettingsContextState = {
		usesMarkets: customerSettings?.usesMarkets || false,
		usesDualProduct: customerSettings?.usesDualProduct || false,
		usesDeviceName: customerSettings?.usesDeviceName || false,
		enabledSettings: mapSettingsToEnums(customerSettings),
	}

	// if (!data) return <CustomLoader showLoader />

	return (
		<CustomerSettingsContext.Provider value={contextValue}>
			{children}
		</CustomerSettingsContext.Provider>
	)
}

const mapSettingsToEnums = (
	settings: CustomerSettings | undefined | null
): CustomerSettingsEnum[] => {
	if (!settings) return []

	const enabledSettings: CustomerSettingsEnum[] = []

	if (settings.usesMarkets) {
		enabledSettings.push(CustomerSettingsEnum.USES_MARKETS)
	}
	if (settings.usesDualProduct) {
		enabledSettings.push(CustomerSettingsEnum.USES_DUAL_PRODUCT)
	}
	if (settings.usesDeviceName) {
		enabledSettings.push(CustomerSettingsEnum.USES_DEVICE_NAME)
	}

	return enabledSettings
}
