import { CircleFilledCheckmarkIcon } from './circle-filled-checkmark'
import { CircleFilledPencilIcon } from './circle-filled-pencil'
import { CircleFilledPlusIcon } from './circle-filled-plus'

import AdvertisingIcon from './advertising.svg'
import AlarmIcon from './alarm.svg'
import AnalitycsIcon from './analitycs.svg'
import ApiIcon from './api.svg'
import ArrowBlazingUpCornerIcon from './arrow-blazing-up-corner.svg'
import ArrowLeftIcon from './arrow-left.svg'
import ArrowLeftBigIcon from './arrow-left-big.svg'
import ArrowRightIcon from './arrow-right.svg'
import ArrowDownIcon from './arrow-down.svg'
import ArrowUpIcon from './arrow-up.svg'
import AddPositionIcon from './add-position.svg'
import ApiPlaygroundIcon from './apiPlayground.svg'

import BarcodeIcon from './barcode.svg'

import BatteryHealth0Icon from './battery-health-0.svg'
import BatteryHealth25Icon from './battery-health-25.svg'
import BatteryHealth50Icon from './battery-health-50.svg'
import BatteryHealth75Icon from './battery-health-75.svg'
import BatteryHealth100Icon from './battery-health-100.svg'

import CalendarIcon from './calendar.svg'
import CalendarTimeIcon from './calendar-time.svg'

import CartIcon from './cart.svg'

import BeaconsIcon from './beacons.svg'

import CancelIcon from './cancel.svg'

import ChannelsIcon from './channels.svg'

import CircleCancelIcon from './circle-cancel.svg'
import CircleCheckmarkIcon from './circle-checkmark.svg'

import CircleMinusIcon from './circle-minus.svg'

import CirclePlusIcon from './circle-plus.svg'

import CircleRightArrowIcon from './circle-right-arrow.svg'

import CheckmarkIcon from './checkmark.svg'

import CheckmarkBottomLineIcon from './checkmark-bottom-line.svg'

import ConfigIcon from './cog.svg'
import ConfigSmallIcon from './cog-small.svg'

import ContentCodes from './content-codes.svg'

import ConnectionPerfectIcon from './connection-perfect.svg'
import ConnnectionNoNetworkIcon from './connection-nonetwork.svg'
import ConnectionStandardIcon from './connection-standard.svg'
import ConnectionBadIcon from './connection-bad.svg'

import CostumersIcon from './costumers.svg'

import ClockIcon from './clock.svg'

import CurrenPositionIcon from './current-position.svg'

import DataInputIcon from './data-input.svg'

import DashboardHardwareIcon from './dashboard-hardware.svg'

import DeadzonesIcon from './deadzones.svg'

import DeviceErrorIcon from './device-error.svg'

import DeviceRefreshingIcon from './device-refreshing.svg'

import DesignShopIcon from './design-shop.svg'

import DollarIcon from './dollar.svg'

import DocumentationIcon from './documentationIcon.svg'

import EditorIcon from './editor.svg'

import E403Icon from './403.svg'
import E404Icon from './404.svg'
import E500Icon from './500.svg'

import EqualsIcon from './equals.svg'

import Experiences from './experiences.svg'

import FillShelfIcon from './fill-shelf.svg'

import FilesIcon from './files.svg'

import FilmIcon from './film.svg'

import HistoryIcon from './history.svg'

import IndividualIcon from './individual.svg'
import ImageFrame from './image-frame.svg'

import ImageIcon from './image-icon.svg'

import InfrastructureIcon from './infrastructureIcon.svg'

import InfiniteRefreshIcon from './infinite-refresh.svg'

import IotDevicesIcon from './iot-devices.svg'

import GreaterThanIcon from './greater-than.svg'

import GrayStatusIcon from './grayStatusIcon.svg'

import GreenStatusIcon from './greenStatusIcon.svg'

import HeatmapIcon from './heatmap.svg'

import LabelListIcon from './smart-label.svg'

import LandingPages from './landing-pages.svg'

import LocationIcon from './location.svg'

import Location from './warehouse-location.svg'

import WarehousePlanogram from './warehouse-planogram.svg'

import FullBasket from './full-basket.svg'

import LowerThanIcon from './lower-than.svg'

import LicensesIcon from './licenses.svg'

import LocationValueIcon from './location-value.svg'

import ManualImportIcon from './manual-import.svg'

import MicrosoftLogoIcon from './microsoft-logo.svg'

import GoogleLogoIcon from './google-logo.svg'

import LinesIcon from './23.svg'

import LinesRotatedIcon from './23-rotated.svg'

import RotateLeft from './rotate-left.svg'

import RotateRight from './rotate-right.svg'

import MinusIcon from './minus.svg'

import MinusBigIcon from './minus-big.svg'

import MoveIcon from './move.svg'

import ModulesIcon from './modules.svg'

import MenuIcon from './menu.svg'

import NumberIcon from './number.svg'

import OvalIcon from './oval.svg'

import FlowIcon from './flow.svg'

import GeofencesIcon from './geofences.svg'

import FlowNoBorderIcon from './flow-no-border.svg'

import FlowRunnersIcon from './flow-runners.svg'

import FlowAutomationIcon from './flow-automation.svg'

import HealthIcon from './health.svg'

import HomeBaketIcon from './home-basket.svg'

import OverviewIcon from './overview.svg'

import CountingIcon from './counting.svg'

import PercentageIcon from './percentage.svg'

import PencilIcon from './pencil.svg'

import PictureIcon from './picture.svg'

import PlanogramIcon from './planogram.svg'

import PlusIcon from './plus.svg'

import PlusBigIcon from './plus-big.svg'

import PlayIcon from './play.svg'

import PlayingIcon from './playing.svg'

import PreviewIcon from './preview.svg'

import permissionsIcon from './permissions.svg'

import ProcessingIcon from './processing.svg'

import ProductEditorIcon from './product-editor.svg'

import ProximityIcon from './proximity.svg'

import ProductsIcon from './products.svg'

import RouterIcon from './router.svg'

import RouterErrorIcon from './routerErr.svg'

import RecurringPeople from './recurring-people.svg'

import ReportsIcon from './reports.svg'

import RetentionTimeIcon from './retention-time.svg'

import RetargetingIcon from './retargeting.svg'

import RetentionShareIcon from './retention-share.svg'

import RedStatusIcon from './redStatusIcon.svg'

import RolesIcon from './roles.svg'

import ScheduleIcon from './schedule.svg'
import SlideIcon from './slide.svg'

import TagIcon from './tag.svg'

import TelemetryIcon from './telemetry.svg'

import TemplatesIcon from './templates.svg'

import TrashBinIcon from './trash-bin.svg'

import UserIcon from './userIcon.svg'

import SdkIcon from './sdk.svg'

import SignageIcon from './signage.svg'

import SmartSensorIcon from './smart-sensor.svg'

import ShelfBarIcon from './shelf-bar.svg'

import StoppedIcon from './stopped.svg'

import ShieldWarningIcon from './shield-warning.svg'

import SettingsIcon from './settings.svg'

import SetupRackIcon from './setup-rack.svg'

import VirtualShelfIcon from './virtual-shelf.svg'

import VideoWall from './video-wall.svg'
import VirtualCategories from './virtual-categories.svg'

import XIcon from './xIcon.svg'

import XBigIcon from './x-big.svg'

import WarningIcon from './warning.svg'

import WaitTimesIcon from './wait-times.svg'
import Supplier from './supplier.svg'
import WarehouseProducts from './warehouse-products.svg'
import WarehouseStock from './warehouse-stock.svg'

import YellowStatusIcon from './yellowStatusIcon.svg'
import IconDuplicate from './icon-duplicate.svg'
import IconDuplicateWhite from './icons-duplicate-white.svg'
import IconTrashWhite from './icons-trash-white.svg'
import Scale from './scale.svg'

import { KeyValueObject } from '@app/@types'
import { CircleFilledArrowIcon } from './circle-filled-arrow'
import { CircleFilledCancelIcon } from './circle-filled-cancel'

export const svgIconsMap: KeyValueObject = {
	advertising: AdvertisingIcon,
	alarm: AlarmIcon,
	analitycs: AnalitycsIcon,
	api: ApiIcon,
	arrowBlazingUpCorner: ArrowBlazingUpCornerIcon,
	apiPlayground: ApiPlaygroundIcon,
	'add-position': AddPositionIcon,
	'arrow-left': ArrowLeftIcon,
	'arrow-left-big': ArrowLeftBigIcon,
	'arrow-right': ArrowRightIcon,
	'arrow-down': ArrowDownIcon,
	'arrow-up': ArrowUpIcon,
	barcode: BarcodeIcon,
	'battery-health-0': BatteryHealth0Icon,
	'battery-health-25': BatteryHealth25Icon,
	'battery-health-50': BatteryHealth50Icon,
	'battery-health-75': BatteryHealth75Icon,
	'battery-health-100': BatteryHealth100Icon,
	calendar: CalendarIcon,
	beacons: BeaconsIcon,
	'calendar-time': CalendarTimeIcon,
	cart: CartIcon,
	cancel: CancelIcon,
	channels: ChannelsIcon,
	checkmark: CheckmarkIcon,
	'checkmark-bottom-line': CheckmarkBottomLineIcon,
	'circle-cancel': CircleCancelIcon,
	'circle-checkmark': CircleCheckmarkIcon,
	'circle-minus': CircleMinusIcon,
	'circle-plus': CirclePlusIcon,
	'circle-right-arrow': CircleRightArrowIcon,
	clock: ClockIcon,
	cog: ConfigIcon,
	cogSmall: ConfigSmallIcon,
	contentCodes: ContentCodes,
	connectionPerfect: ConnectionPerfectIcon,
	connectionNoNetwork: ConnnectionNoNetworkIcon,
	connectionStandard: ConnectionStandardIcon,
	connectionBad: ConnectionBadIcon,
	costumers: CostumersIcon,
	'current-position': CurrenPositionIcon,
	'data-input': DataInputIcon,
	dashboardHardware: DashboardHardwareIcon,
	deadzones: DeadzonesIcon,
	dollar: DollarIcon,
	'device-error': DeviceErrorIcon,
	'device-refreshing': DeviceRefreshingIcon,
	designShop: DesignShopIcon,
	documentation: DocumentationIcon,
	editor: EditorIcon,
	E403: E403Icon,
	E404: E404Icon,
	E500: E500Icon,
	equals: EqualsIcon,
	experiences: Experiences,
	fillShelf: FillShelfIcon,
	flow: FlowIcon,
	flowRunners: FlowRunnersIcon,
	flowAutomation: FlowAutomationIcon,
	files: FilesIcon,
	film: FilmIcon,
	geofences: GeofencesIcon,
	grayStatusIcon: GrayStatusIcon,
	greenStatusIcon: GreenStatusIcon,
	'flow-no-border': FlowNoBorderIcon,
	'greater-than': GreaterThanIcon,
	health: HealthIcon,
	homeBasket: HomeBaketIcon,
	iotDevices: IotDevicesIcon,
	infrastructure: InfrastructureIcon,
	'infinite-refresh': InfiniteRefreshIcon,
	'lower-than': LowerThanIcon,
	labelList: LabelListIcon,
	landingPages: LandingPages,
	licenses: LicensesIcon,
	locationValue: LocationValueIcon,
	'full-basket': FullBasket,
	'warehouse-planogram': WarehousePlanogram,
	supplier: Supplier,
	'warehouse-products': WarehouseProducts,
	'warehouse-stock': WarehouseStock,
	location: Location,
	manualImport: ManualImportIcon,
	'microsoft-logo': MicrosoftLogoIcon,
	'google-logo': GoogleLogoIcon,
	lines: LinesIcon,
	linesRotated: LinesRotatedIcon,
	rotateLeft: RotateLeft,
	rotateRight: RotateRight,
	minus: MinusIcon,
	minusBig: MinusBigIcon,
	move: MoveIcon,
	modules: ModulesIcon,
	menu: MenuIcon,
	number: NumberIcon,
	heatmap: HeatmapIcon,
	history: HistoryIcon,
	individual: IndividualIcon,
	imageFrame: ImageFrame,
	ImageIcon: ImageIcon,
	oval: OvalIcon,
	overview: OverviewIcon,
	pencil: PencilIcon,
	counting: CountingIcon,
	percentage: PercentageIcon,
	picture: PictureIcon,
	place: LocationIcon,
	planogram: PlanogramIcon,
	play: PlayIcon,
	playing: PlayingIcon,
	plus: PlusIcon,
	plusBig: PlusBigIcon,
	preview: PreviewIcon,
	processing: ProcessingIcon,
	productEditor: ProductEditorIcon,
	proximity: ProximityIcon,
	products: ProductsIcon,
	permissions: permissionsIcon,
	reports: ReportsIcon,
	recurringPeople: RecurringPeople,
	retentionTime: RetentionTimeIcon,
	retargeting: RetargetingIcon,
	retentionShare: RetentionShareIcon,
	redStatusIcon: RedStatusIcon,
	router: RouterIcon,
	routerErr: RouterErrorIcon,
	roles: RolesIcon,
	schedule: ScheduleIcon,
	slide: SlideIcon,
	setupRack: SetupRackIcon,
	tag: TagIcon,
	telemetry: TelemetryIcon,
	templates: TemplatesIcon,
	user: UserIcon,
	signage: SignageIcon,
	sdk: SdkIcon,
	settings: SettingsIcon,
	virtualShelf: VirtualShelfIcon,
	videoWall: VideoWall,
	virtualCategories: VirtualCategories,
	'smart-sensor': SmartSensorIcon,
	'shelf-bar': ShelfBarIcon,
	stopped: StoppedIcon,
	'shield-warning': ShieldWarningIcon,
	'trash-bin': TrashBinIcon,
	xIcon: XIcon,
	xBig: XBigIcon,
	warning: WarningIcon,
	waitTimes: WaitTimesIcon,
	yellowStatusIcon: YellowStatusIcon,
	scale: Scale,
	'icon-duplicate': IconDuplicate,
	'icon-duplicate-white': IconDuplicateWhite,
	'icon-trash-white': IconTrashWhite,
}

export {
	AddPositionIcon,
	ArrowBlazingUpCornerIcon,
	ArrowLeftIcon,
	ArrowRightIcon,
	ApiPlaygroundIcon,
	CalendarIcon,
	CalendarTimeIcon,
	CancelIcon,
	CircleCancelIcon,
	CircleCheckmarkIcon,
	CircleFilledArrowIcon,
	CircleFilledCancelIcon,
	CircleFilledCheckmarkIcon,
	CircleFilledPencilIcon,
	CircleFilledPlusIcon,
	CircleRightArrowIcon,
	CirclePlusIcon,
	ClockIcon,
	CheckmarkIcon,
	CurrenPositionIcon,
	DeviceErrorIcon,
	GrayStatusIcon,
	GreenStatusIcon,
	RedStatusIcon,
	DeviceRefreshingIcon,
	DocumentationIcon,
	MicrosoftLogoIcon,
	FilesIcon,
	FilmIcon,
	LabelListIcon,
	LicensesIcon,
	ModulesIcon,
	ImageFrame,
	ImageIcon,
	PlayIcon,
	permissionsIcon,
	RouterIcon,
	RouterErrorIcon,
	RolesIcon,
	SdkIcon,
	SettingsIcon,
	WarningIcon,
	YellowStatusIcon,
	CheckmarkBottomLineIcon,
	XIcon,
	IconDuplicate,
	IconDuplicateWhite,
	IconTrashWhite,
	Scale,
}
