export const setCookie = (name: string, value: string, days: number = 7) => {
	const expires = new Date(Date.now() + days * 864e5).toUTCString()
	document.cookie =
		name +
		'=' +
		encodeURIComponent(value) +
		'; expires=' +
		expires +
		'; path=/'
}

export const getCookie = (name: string, cookies?: string): string | null => {
	const cookieStr = cookies ?? document.cookie
	const nameEQ = name + '='
	const ca = cookieStr.split(';')
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i]
		while (c.charAt(0) === ' ') c = c.substring(1, c.length)
		if (c.indexOf(nameEQ) === 0)
			return decodeURIComponent(c.substring(nameEQ.length, c.length))
	}
	return null
}

export const deleteCookie = (name: string) => {
	document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

export const getCookiesFromContext = (context: any) =>
	context && context.req && context.req.headers
		? context.req.headers.cookie
		: document.cookie
