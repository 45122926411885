import React, { ComponentType, FC } from 'react'
import App from 'next/app'
import { ApolloClient } from '@apollo/client'
import withApollo from 'next-with-apollo'
import Immutable from 'immutable'
import Layout from '../layout'
import {
	Theme,
	StyledEngineProvider,
	ThemeProvider,
} from '@mui/material/styles'
import theme from '../lib/materialui/theme'
import shelfTheme from '@app/lib/materialui/shelfTheme'
import Head from 'next/head'
import { ApolloProvider } from '@apollo/client'
import createApolloClient from '../lib/graphql/createApolloClient'
import createApolloDevelopment from '../lib/graphql/createApolloDevelopment'
import { AlertsProvider, CustomDialogProvider } from '@app/lib/packages'
import { LocaleProvider } from '@app/lib/lang'
import { authCheck } from '@app/utils/auth'
import { CurrentUserProvider } from '@app/lib/packages/currentUser'
import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from '@app/lib/microsoft/config'
import { GoogleOAuthProvider } from '@app/lib/packages/GoogleOAuthProvider'
import { CustomerSettingsProvider } from '@app/lib/packages/customerSettings/CustomerSettingsProvider'
import { getCustomerFromRequest } from '@app/lib/packages/customerSettings/utils'
import '../../public/graphiql.css'
import 'react-calendar-timeline/lib/Timeline.css'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import 'draft-js-mention-plugin/lib/plugin.css'
import 'draft-js-alignment-plugin/lib/plugin.css'
import '@app/pageComponents/TemplateEditor/Editor/editor.css'
import '@simonwep/pickr/dist/themes/nano.min.css'
import 'public/fonts/fonts.css'

declare module '@mui/styles/defaultTheme' {
	interface DefaultTheme extends Theme {}
}

;(Immutable.Iterable as any).noLengthWarning = true

interface AppContentProps {
	apollo: ApolloClient<any>
	component: ComponentType
	pageProps: any
	customer?: string
}

const AppContent: FC<AppContentProps> = ({
	apollo,
	component: Component,
	pageProps,
	customer,
}) => {
	const msalInstance = new PublicClientApplication(msalConfig)

	return (
		<>
			<CustomHead />
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={theme}>
						<LocaleProvider>
							<ApolloProvider client={apollo}>
								<CustomerSettingsProvider customer={customer}>
									<CurrentUserProvider>
										<Layout>
											<CustomDialogProvider>
												<AlertsProvider>
													<MsalProvider
														instance={msalInstance}
													>
														<GoogleOAuthProvider>
															<Component
																{...pageProps}
															/>
														</GoogleOAuthProvider>
													</MsalProvider>
												</AlertsProvider>
											</CustomDialogProvider>
										</Layout>
									</CurrentUserProvider>
								</CustomerSettingsProvider>
							</ApolloProvider>
						</LocaleProvider>
					</ThemeProvider>
				</StyledEngineProvider>
			</LocalizationProvider>
		</>
	)
}

const NoLayoutAppContent: FC<AppContentProps> = ({
	apollo,
	component: Component,
	pageProps,
}) => {
	return (
		<ApolloProvider client={apollo}>
			<Component {...pageProps} />
		</ApolloProvider>
	)
}

const AuthorizedAppContent = AppContent
const AuthorizedNoLayoutAppContent = NoLayoutAppContent

class MyApp extends App {
	componentDidMount() {
		// Remove the server-side injected CSS.
		const jssStyles = document.querySelector('#jss-server-side')
		if (jssStyles) {
			;(jssStyles.parentElement as any).removeChild(jssStyles)
		}
	}

	static async getInitialProps({ Component, ctx }: any) {
		let pageProps = {}
		const isAuthenticated = await authCheck(ctx)

		// Extract customer from server request if available
		let customer = null
		if (ctx && ctx.req) {
			customer = getCustomerFromRequest(ctx.req)
		}

		if (isAuthenticated && Component.getInitialProps) {
			pageProps = await Component.getInitialProps(ctx)
		}

		return {
			pageProps,
			customer,
		}
	}

	render() {
		const { Component, pageProps, apollo, customer }: any = this.props
		let layout = null

		if (Component.hasOwnProperty('layout')) {
			layout = Component['layout']
		}

		if (layout) {
			if (layout === 'errorPage') {
				return (
					<>
						<StyledEngineProvider injectFirst>
							<ThemeProvider theme={theme}>
								<Component {...pageProps} />
							</ThemeProvider>
						</StyledEngineProvider>
					</>
				)
			}

			if (layout === 'healthCheck') {
				return <Component {...pageProps} />
			}

			if (layout === 'empty') {
				return (
					<AuthorizedNoLayoutAppContent
						apollo={apollo}
						component={Component}
						pageProps={pageProps}
					/>
				)
			}
		}
		if (layout === 'virtualShelfHeadless') {
			return (
				<>
					<CustomHead />
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={shelfTheme}>
							<LocaleProvider>
								<ApolloProvider client={apollo}>
									<CustomDialogProvider>
										<AlertsProvider>
											<Component {...pageProps} />
										</AlertsProvider>
									</CustomDialogProvider>
								</ApolloProvider>
							</LocaleProvider>
						</ThemeProvider>
					</StyledEngineProvider>
				</>
			)
		}

		if (layout === 'loginPage')
			return (
				<>
					<CustomHead />
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={theme}>
							<LocaleProvider>
								<ApolloProvider client={apollo}>
									<CustomDialogProvider>
										<AlertsProvider>
											<Component {...pageProps} />
										</AlertsProvider>
									</CustomDialogProvider>
								</ApolloProvider>
							</LocaleProvider>
						</ThemeProvider>
					</StyledEngineProvider>
				</>
			)

		return (
			<AuthorizedAppContent
				apollo={apollo}
				component={Component}
				pageProps={pageProps}
				customer={customer}
			/>
		)
	}
}

const getCookie = (context: any) =>
	context && context.req && context.req.headers
		? context.req.headers.cookie
		: document.cookie

export default withApollo(({ ctx }) =>
	process.env.NODE_ENV.trim() == 'development'
		? createApolloDevelopment({}, ctx, { getToken: () => getCookie(ctx) })
		: createApolloClient({}, ctx, {
				getToken: () => getCookie(ctx),
		  })
)(MyApp)

const CustomHead = () => {
	return (
		<Head>
			<title>Agitron Dashboard</title>
			<meta
				name="viewport"
				content="minimum-scale=1, initial-scale=1, width=device-width"
			/>
			<link href="/fonts/agitronfonts/style.css" rel="stylesheet" />
		</Head>
	)
}
