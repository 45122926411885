import React, { FC, useMemo } from 'react'
import NextLink from 'next/link'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { TranslateMessage } from '@app/lib/lang'
import { useCurrentRoute } from '@app/lib/hooks'
import { getRouteTree, themeColors } from '@app/constants'
import { BreadcrumbItem } from '@app/@types'
import { CustomIconFromValue } from '.'

interface CustomBreadcrumbsProps {
	breadcrumbs?: BreadcrumbItem[]
	additionalBreadCrumbs?: BreadcrumbItem[]
}

export const CustomBreadcrumbs: FC<CustomBreadcrumbsProps> = ({
	breadcrumbs: breadcrumbsFromProps,
	additionalBreadCrumbs = [],
}) => {
	const { route } = useCurrentRoute()

	const breadcrumbs = useMemo(() => {
		if (breadcrumbsFromProps?.length) {
			return breadcrumbsFromProps
		}

		let routeBreadcrumbs: BreadcrumbItem[] = []

		if (route) {
			routeBreadcrumbs = getRouteTree(route).map((q) => ({
				label: q.label || '',
				link: q.to,
				linkAs: q.to,
			}))
		}

		return routeBreadcrumbs
	}, [route, breadcrumbsFromProps])

	return (
		<Breadcrumbs
			separator={
				<CustomIconFromValue
					icon="svg arrow-right"
					style={{ color: themeColors.pinkishGrey }}
				/>
			}
			aria-label="breadcrumb"
		>
			{breadcrumbs.map((x, i) =>
				x.link && i !== breadcrumbs.length - 1 ? (
					<NextLink key={x.label} href={x.link} passHref>
						<Link
							color="inherit"
							style={{
								textDecoration: 'none',
							}}
						>
							<TranslateMessage id={x.label!} isTitle />
						</Link>
					</NextLink>
				) : (
					<Typography key={x.label} color="textPrimary">
						<TranslateMessage id={x.label!} isTitle />
					</Typography>
				)
			)}
		</Breadcrumbs>
	)
}
