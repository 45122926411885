import React, { FC, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import CssBaseline from '@mui/material/CssBaseline'
import Collapse from '@mui/material/Collapse'
import { Box } from '@mui/material'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import useMediaQuery from '@mui/material/useMediaQuery'

import AppBar from './components/AppBar'
import SideBar from './components/SideBar'
import {
	isMenuItemInPathname,
	menu,
	RouteItem,
	themeColors,
} from '@app/constants'
import { SiderbarExtension } from './components'

const drawerWidth = 220

interface StyleProps {
	open: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	root: {
		display: 'flex',
		height: '100%',
	},
	content: {
		background: themeColors.backgroundColor,
		flexGrow: 1,
		paddingTop: theme.spacing(8),
		//paddingBottom: theme.spacing(3),
		// paddingLeft: theme.spacing(3),
		// paddingRight: theme.spacing(3),
		//width: '100%',
		width: '100vw',
		[theme.breakpoints.up('sm')]: {
			width: ({ open }) => `calc(100vw - ${open ? '220px' : '0'})`,
		},
		[theme.breakpoints.up('md')]: {
			width: ({ open }) =>
				`calc(100vw - 114px - ${open ? '220px' : '0'})`,
		},
	},
	subMenuWrapper: {
		flexShrink: 0,
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
		},
	},
	subMenu: {
		background: '#e4eaec',
		position: 'fixed',
		height: '100%',
		zIndex: 1,
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
		},
		[theme.breakpoints.down('lg')]: {
			zIndex: 100,
			marginLeft: theme.spacing(14) + 2,
		},
		overflowY: 'auto',
	},
	drawerPaper: {
		width: drawerWidth,
	},
	toolbar: theme.mixins.toolbar,
	iconSub: {
		fontSize: 18,
		// minWidth: 36
	},
	listSub: {
		color: 'white',
		// paddingTop: 13,
		// paddingBottom: 13,
		paddingLeft: 60,
		borderBottom: 'solid 1px #4e5d68',
	},
}))

const Layout: FC = ({ children }) => {
	useMediaQuery<Theme>((theme) => theme.breakpoints.down('xl'))
	const { pathname } = useRouter()
	const [open, setOpen] = useState(true)
	const [itemExpanded, setItemExpanded] = useState<RouteItem | null>(null)

	// const { t } = useTranslation()
	const classes = useStyles({ open })
	useEffect(() => {
		const foundRoute = menu.find((q) => isMenuItemInPathname(q, pathname))

		if (foundRoute) {
			if (foundRoute.id.includes('.')) {
				setItemExpanded(foundRoute)
			}

			setOpen(true)
		}
	}, [])

	const handleClick = (item: RouteItem) => {
		if (item.subs && item.subs.length > 0) {
			setOpen(true)
			if (item.id !== itemExpanded?.id) {
				setItemExpanded(item)
			}
		} else {
			setItemExpanded(null)
		}
	}

	const handleDrawer = () => {
		if (!open && itemExpanded == null) {
			const menuItem = menu.find(
				(x, i) => i > 0 && x.to && pathname.includes(x.to)
			)

			if (menuItem) {
				setItemExpanded(menuItem)
			}
		}
		setOpen(!open)
	}

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar handleDrawerOpen={handleDrawer} open={open} />
			<SideBar onMenuItemClick={handleClick} open={open} />
			<Collapse in={open && !!itemExpanded} timeout="auto" unmountOnExit>
				<Box className={classes.subMenuWrapper}>
					<Box className={classes.subMenu}>
						<List component="div" disablePadding>
							<div className={classes.toolbar} />
							<Divider />
							{itemExpanded && (
								<SiderbarExtension menuItem={itemExpanded} />
							)}
						</List>
					</Box>
				</Box>
			</Collapse>
			<main className={classes.content}>{children}</main>
		</div>
	)
}

export default Layout
