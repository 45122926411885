import Head from 'next/head'
import Typography from '@mui/material/Typography'
import { Box, Stack } from '@mui/material'
import { TranslateMessage, useTranslation } from '@app/lib/lang'
import { CustomDivider } from './CustomDivider'
import { BreadcrumbItem } from '@app/@types'
import { CustomBreadcrumbs } from './Breadcrumbs'
import { themeColors } from '@app/constants'
import { ReactNode } from 'react'
interface HeaderProps {
	children?: ReactNode
	heading?: string
	description?: string
	hideBottomDivider?: boolean
	breadcrumbs?: BreadcrumbItem[]
	additionalBreadcrumbs?: BreadcrumbItem[]
}

export default function Header({
	children,
	heading = '',
	description = '',
	hideBottomDivider = false,
	breadcrumbs,
	additionalBreadcrumbs,
}: HeaderProps) {
	const { t } = useTranslation()

	return (
		<>
			{heading && (
				<Head>
					<title>{t(heading)}</title>
				</Head>
			)}

			<Box>
				{breadcrumbs && (
					<Box mb="16px">
						<CustomBreadcrumbs
							breadcrumbs={breadcrumbs}
							additionalBreadCrumbs={additionalBreadcrumbs}
						/>
					</Box>
				)}

				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					gap="10px"
				>
					<Box>
						<Typography variant="h3">
							<TranslateMessage id={heading} isTitle />
						</Typography>
						<Typography
							color={themeColors.brownishGrey}
							fontSize={14}
						>
							<TranslateMessage id={description} />
						</Typography>
					</Box>

					<Stack direction="row" justifyContent="flex-end" gap="10px">
						{children}
					</Stack>
				</Stack>
			</Box>

			{!hideBottomDivider && <CustomDivider mb={3} mt={3} />}
		</>
	)
}
