import theme from '@app/lib/materialui/theme'
import { Box } from '@mui/material'
import React, { FC } from 'react'

interface PageContentSectionProps {
	className?: string
}

export const PageContentSection: FC<PageContentSectionProps> = ({
	children,
}) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				paddingLeft: theme.spacing(3),
				paddingRight: theme.spacing(3),
			}}
		>
			{children}
		</Box>
	)
}
