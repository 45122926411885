import { InMemoryCache, ApolloLink, ApolloClient } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import {
	SERVER_MESSAGES,
	AUTH,
	CLIENT_AUTH_REQUEST_TYPE,
	APOLLO_DEFAULT_OPTIONS,
} from './config'
import { NextPageContext } from 'next'
import Router from 'next/router'
import { createUploadLink } from 'apollo-upload-client'
import { bodyTransformerLink, customUploadFetch } from './links'
import { getCookie, getCookiesFromContext } from '../utils/cookies'

const { UNAUTHORIZED, FORBIDDEN } = SERVER_MESSAGES

let apolloClient: any = null
// Polyfill fetch() on the server (used by apollo-client)

export const createApolloClient = (host: string, ctx?: any) => {
	const link = host.includes('localhost')
		? 'http://localhost:4000/api'
		: `https://${host}/api`

	const isBrowser = typeof window !== 'undefined'

	const cookies =
		process.env.NODE_ENV === 'development' && ctx
			? {
					['x-connector-token']: getCookie(
						'x-connector-token',
						ctx.req.headers.cookie
					),
					['x-connector-refresh-token']: getCookie(
						'x-connector-refresh-token',
						ctx.req.headers.cookie
					),
			  }
			: ctx
			? ctx && { Cookie: getCookiesFromContext(ctx) }
			: {}

	return new ApolloClient({
		uri: link,
		cache: new InMemoryCache(),
		credentials: 'include',
		headers: {
			[AUTH.STRATEGIES.CLIENT.AUTH_HEADER]: CLIENT_AUTH_REQUEST_TYPE,
			...cookies,
		},
	})
}

const create = (
	initialState: any,
	ctx: NextPageContext | undefined | null,
	{ getToken }: any
) => {
	const isBrowser = typeof window !== 'undefined'
	let browserUrl = ''

	isBrowser &&
		(browserUrl =
			window.location.protocol + '//' + window.location.hostname + '/api')

	const httpLink = createUploadLink({
		uri: !isBrowser ? `https://${ctx!.req!.headers.host}/api` : browserUrl,
		credentials: 'include',
		headers: {
			[AUTH.STRATEGIES.CLIENT.AUTH_HEADER]: CLIENT_AUTH_REQUEST_TYPE,
		},
		fetch: !isBrowser && customUploadFetch,
	} as any)

	const authLink = setContext((_, { headers }) => {
		return {
			headers: {
				...headers,
				Cookie: getToken(),
			},
		}
	})

	const errorLink = onError(({ graphQLErrors, networkError }: any) => {
		console.log('error', isBrowser, graphQLErrors, networkError)
		if (graphQLErrors && graphQLErrors.filter((e: any) => e).length > 0) {
			graphQLErrors.map(({ message = '' }: any) => {
				if ('Not Authorised!' === message) {
					if (ctx && ctx.res) {
						ctx.res.writeHead(302, {
							Location: '/login',
						})
						ctx.res.end()
					} else {
						Router.push('/login')
					}
				}
				if (UNAUTHORIZED === message) {
					console.warn(
						`You've attempted to access ${UNAUTHORIZED} section`
					)
				}
				if (FORBIDDEN === message) {
					console.warn(`You've attempted a ${FORBIDDEN} action`)
				}
				return null
			})
		}
		if (networkError && networkError.statusCode === 401) {
			console.warn(UNAUTHORIZED)
		}
		if (networkError && networkError.statusCode === 403) {
			console.warn(FORBIDDEN)
		}
		if (networkError && networkError.statusCode >= 500) {
			console.warn('SERVER ERROR')
		}
	})

	let links = [authLink, errorLink, bodyTransformerLink, httpLink as any]

	const cache = new InMemoryCache({
		addTypename: false,
	}).restore(initialState || {})

	const link = ApolloLink.from(links)

	// Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient

	return new ApolloClient({
		connectToDevTools: isBrowser,
		defaultOptions: APOLLO_DEFAULT_OPTIONS,
		ssrMode: !isBrowser, // Disables forceFetch on the server (so queries are only run once)
		link,
		cache,
		resolvers: {},
	})
}

export default function initApollo(
	initialState: any,
	ctx: NextPageContext | undefined | null,
	options: any
) {
	// data isn't shared between connections (which would be bad)!!!!!!
	if (typeof window === 'undefined') {
		return create(initialState, ctx, options)
	}

	// Reuse client on the client-side
	if (!apolloClient) {
		apolloClient = create(initialState, null, options)
	}

	return apolloClient
}
