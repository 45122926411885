import { Box, IconButton, Stack, Typography } from '@mui/material'
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import { FC, MouseEventHandler } from 'react'
import { TranslateMessage } from '@app/lib/lang'
import { CustomIconFromValue } from './CustomIconFromValue'
import { themeColors } from '@app/constants'
import theme from '@app/lib/materialui/theme'

interface CustomDrawerProps extends DrawerProps {
	width?: number
	isFullHeight?: boolean
	title?: string
	description?: string
}

export const CustomDrawer: FC<CustomDrawerProps> = ({
	children,
	onClose,
	width,
	isFullHeight,
	title,
	description,
	...otherProps
}) => {
	const onCloseClick: MouseEventHandler = (e) => {
		if (onClose) {
			onClose(e, 'backdropClick')
		}
	}

	return (
		<Drawer
			sx={{
				'& .MuiDrawer-paper': {
					width: width ?? '50%',
					minWidth: width ?? '800px',
					marginTop: '64px',
				},
				height: '100%',
			}}
			anchor="right"
			onClose={onClose}
			{...otherProps}
		>
			<Box
				mt={2}
				ml={4}
				mr={4}
				// mb={10}
				height={isFullHeight ? 'calc(100% - 104px)' : undefined}
			>
				<Box height="100%">
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						mb={2}
					>
						{title ? (
							<Typography variant="h4">
								<TranslateMessage id={title} />
							</Typography>
						) : (
							<div />
						)}

						<IconButton onClick={onCloseClick} size="large">
							<CustomIconFromValue icon="svg cancel" />
						</IconButton>
					</Box>

					{description ? (
						<Typography
							sx={{
								color: themeColors.brownishGrey,
								marginBottom: theme.spacing(3),
							}}
						>
							<TranslateMessage id={description} />
						</Typography>
					) : (
						<div />
					)}

					{children}
				</Box>
			</Box>
		</Drawer>
	)
}
