import { useIntl } from 'react-intl'

export type TFunction = (key: string, values?: any) => string
export default function useTranslation() {
	const { formatMessage, locale } = useIntl()

	const t: TFunction = (key: string, values?: any) => {
		if (key) {
			return formatMessage({ id: key }, values)
		}

		return ''
	}

	return {
		locale,
		t,
	}
}
