import { GoogleOAuthProvider as GoogleProvider } from '@react-oauth/google'
import { ReactNode } from 'react'

interface GoogleOAuthProviderProps {
	children: ReactNode
}

export function GoogleOAuthProvider({ children }: GoogleOAuthProviderProps) {
	const clientId =
		'271748484750-m4kacnhickfk3v4tr2h4ldrhm4mrp723.apps.googleusercontent.com'!

	if (!clientId) {
		console.error('Google OAuth client ID is not configured')
		return <>{children}</>
	}

	return <GoogleProvider clientId={clientId}>{children}</GoogleProvider>
}
