import { InMemoryCache, ApolloLink, ApolloClient, gql } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import {
	SERVER_MESSAGES,
	AUTH,
	CLIENT_AUTH_REQUEST_TYPE,
	APOLLO_DEFAULT_OPTIONS,
} from './config'
import { NextPageContext } from 'next'
import { createUploadLink } from 'apollo-upload-client'
import { bodyTransformerLink, customUploadFetch } from './links'
import { getCookie } from '../utils/cookies'
import { createFragmentRegistry } from '@apollo/client/cache'
import { ROOT_FRAGMENT } from '../hooks/useGetRootFromCache'

const { UNAUTHORIZED, FORBIDDEN } = SERVER_MESSAGES

let apolloClient: any = null

const create = (
	initialState: any,
	_: NextPageContext | undefined | null,
	{ getToken }: any
) => {
	const API_URL = process.env.NEXT_PUBLIC_API?.includes('localhost')
		? `http://${process.env.NEXT_PUBLIC_API}/api`
		: `https://${process.env.NEXT_PUBLIC_API}/api`

	const isBrowser = typeof window !== 'undefined'

	const httpLink = createUploadLink({
		// uri: `https://staging.agitron.dev/api`, // !isBrowser ? `https://${ctx?.req?.headers.host}/graphql` : browserUrl,
		uri: API_URL,
		// credentials: "include",
		headers: {
			[AUTH.STRATEGIES.CLIENT.AUTH_HEADER]: CLIENT_AUTH_REQUEST_TYPE,
		},
		fetch: !isBrowser && customUploadFetch,
	} as any)

	const authLink = setContext((_, { headers }) => {
		if (isBrowser) {
			const token = getToken()

			return {
				headers: {
					...headers,
					['x-connector-token']: getCookie('x-connector-token'),
					['x-connector-refresh-token']: getCookie(
						'x-connector-refresh-token'
					),
				},
			}
		}

		return {
			headers,
		}
	})

	const errorLink = onError(({ graphQLErrors, networkError }: any) => {
		console.log('error', isBrowser, graphQLErrors, networkError)
		if (graphQLErrors && graphQLErrors.filter((e: any) => e).length > 0) {
			graphQLErrors.map(({ message = '' }: any) => {
				if (UNAUTHORIZED === message) {
					console.warn(
						`You've attempted to access ${UNAUTHORIZED} section`
					)
				}
				if (FORBIDDEN === message) {
					console.warn(`You've attempted a ${FORBIDDEN} action`)
				}
				return null
			})
		}
		if (networkError && networkError.statusCode === 401) {
			console.warn(UNAUTHORIZED)
		}
		if (networkError && networkError.statusCode === 403) {
			console.warn(FORBIDDEN)
		}
		if (networkError && networkError.statusCode >= 500) {
			console.warn('SERVER ERROR')
		}
	})

	let links = [authLink, errorLink, bodyTransformerLink, httpLink as any]

	const cache = new InMemoryCache({
		addTypename: false,
		// fragments: createFragmentRegistry(ROOT_FRAGMENT),
	}).restore(initialState || {})

	const link = ApolloLink.from(links)

	// Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient

	return new ApolloClient({
		connectToDevTools: isBrowser,
		defaultOptions: APOLLO_DEFAULT_OPTIONS,
		ssrMode: !isBrowser, // Disables forceFetch on the server (so queries are only run once)
		link,
		cache,
		resolvers: {},
	})
}

export default function initApollo(
	initialState: any,
	ctx: NextPageContext | undefined | null,
	options: any
) {
	// Make sure to create a new client for every server-side request so that data
	// isn't shared between connections (which would be bad)
	if (typeof window === 'undefined') {
		return create(initialState, ctx, options)
	}

	// Reuse client on the client-side
	if (!apolloClient) {
		apolloClient = create(initialState, null, options)
	}

	return apolloClient
}
