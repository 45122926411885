import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import { menu, RouteItem } from '@app/constants'
import { SidebarMainItem } from './SidebarMainItem'
import { usePermissions } from '@app/lib/packages/permissions'
import { styled } from '@mui/material'

interface SideBarProps {
	onMenuItemClick: (menuItem: RouteItem) => void
	open: Boolean
}

export default function SideBar({ onMenuItemClick, open }: SideBarProps) {
	const { hasRoutePermission } = usePermissions()

	const permittedMenuItems = menu.filter((q) => hasRoutePermission(q))

	return (
		<StyledDrawer variant="permanent" open={!!open}>
			<Toolbar />
			<Divider />
			<List style={{ paddingTop: 0 }}>
				{permittedMenuItems.map((item) => (
					<SidebarMainItem
						key={item.id}
						menuItem={item}
						onClick={onMenuItemClick}
					/>
				))}
			</List>
		</StyledDrawer>
	)
}

const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
	background: theme.palette.secondary.dark,
	width: open ? 110 : 0,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	...(open
		? {
				// width: theme.spacing(14) + 2,
				'& .MuiDrawer-paper': {
					width: theme.spacing(14) + 2,
					background: theme.palette.secondary.dark,
					border: 'none',
				},
		  }
		: {
				// width: 0,
				[theme.breakpoints.up('sm')]: {
					width: theme.spacing(14) + 2,
				},
				'& .MuiDrawer-paper': {
					width: 0,
					background: theme.palette.secondary.dark,
					border: 'none',
					[theme.breakpoints.up('sm')]: {
						width: theme.spacing(14) + 2,
					},
				},
		  }),
}))

// Create a styled component for the toolbar
const Toolbar = styled('div')(({ theme }) => ({
	color: 'white',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	marginTop: '8px',
	// Using the MUI toolbar mixin properties but in a styled component
	// instead of spreading them in inline styles
	minHeight: 64,
	[theme.breakpoints.up('xs')]: {
		minHeight: 48,
	},
	[theme.breakpoints.up('sm')]: {
		minHeight: 64,
	},
}))
