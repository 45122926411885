import { getCookie } from '../utils/cookies'

const DEVELOPMENT_STORAGE_API_URL =
	process.env.NEXT_PUBLIC_STORAGE_API?.trim()?.includes('localhost')
		? `http://${process.env.NEXT_PUBLIC_STORAGE_API?.trim()}`
		: `https://${process.env.NEXT_PUBLIC_STORAGE_API?.trim()}`

export const getImage = async (filename: string) => {
	const IMAGE_URL =
		process.env.NODE_ENV === 'development'
			? `${DEVELOPMENT_STORAGE_API_URL}/storage/access/`
			: window.location.protocol +
			  '//' +
			  window.location.hostname +
			  `/storage/access/`
	try {
		const token = getCookie('storage_token')

		const res = await fetch(IMAGE_URL + filename, {
			headers: {
				'Content-Type': 'application/octet-stream',
				Authorization: `Bearer ${token}`,
			},
		})
		const blob = await res.blob()

		return blob
	} catch (e) {
		console.log(e)
		return null
	}
}
