import { Box } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { FC, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { CustomIconFromValue } from './CustomIconFromValue'
import CustomTextField from './CustomTextField'
import { themeColors } from '@app/constants'
import { useMultiUploadMutation } from '@app/lib/graphql'
import { useAlerts } from '@app/lib/packages'

export interface UploadedFile {
	id: string
	originalname: string
	url: string
}

const useStyles = makeStyles((theme) =>
	createStyles({
		inputEndAdornment: {
			borderLeftColor: themeColors.borderColor,
			borderLeftStyle: 'solid',
			borderLeftWidth: '1px',
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(2),
			paddingTop: theme.spacing(1),
		},
	})
)

interface CustomTextFileFieldProps {
	multiple?: boolean
	required?: boolean
	onChange?: (acceptedFiles: File[]) => void
	onFileUploadSuccess?: (uploadedFiles: UploadedFile[]) => void
	value?: string
}

export const CustomTextFileField: FC<CustomTextFileFieldProps> = ({
	multiple = true,
	required,
	value = '',
	onChange,
	onFileUploadSuccess,
}) => {
	const { addErrorAlert } = useAlerts()
	const [uploadFiles] = useMultiUploadMutation()
	const onDrop = useCallback(async (acceptedFiles: File[]) => {
		if (onChange) {
			onChange(acceptedFiles)
		} else {
			try {
				const result = await uploadFiles({
					variables: { files: acceptedFiles },
				})

				if (result.data?.multiUpload) {
					if (onFileUploadSuccess) {
						onFileUploadSuccess(result.data.multiUpload as any)
					}
				}
			} catch (e) {
				addErrorAlert('shared.fileUpload.failed')
			}
		}
	}, [])
	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		multiple,
	})

	const classes = useStyles()

	return (
		<Box {...getRootProps()}>
			<input {...getInputProps()} />
			<CustomTextField
				readOnly
				placeholder="shared.fileUpload.chooseFile"
				endAdornment={
					<Box className={classes.inputEndAdornment}>
						<CustomIconFromValue
							icon="icon icon-53"
							style={{ fontSize: '1.2rem' }}
						/>
					</Box>
				}
				value={value}
			/>
		</Box>
	)
}
