import { CustomerSettingsEnum } from './types'

/**
 * Extract customer identifier from host
 * Works in both client and server environments
 */
export const getCustomerFromHost = (host: string): string => {
	if (process.env.NODE_ENV === 'development') {
		if (host.includes('localhost')) {
			return 'staging'
		}
	}

	return host.split('.')[0]
}

/**
 * Get customer identifier from server request
 * To be used in getServerSideProps or middleware
 */
export const getCustomerFromRequest = (req: any): string => {
	const host = req.headers.host

	if (process.env.NODE_ENV === 'development') {
		if (host.includes('localhost')) {
			return 'staging'
		}
	}

	return getCustomerFromHost(host)
}

/**
 * Check if customer setting is enabled
 */
export const hasCustomerSetting = (customerSetting: CustomerSettingsEnum) => {
	return customerSetting === CustomerSettingsEnum.USES_MARKETS
}
