import classNames from 'classnames'
import { FC } from 'react'
import Link from 'next/link'
import Button, { ButtonProps } from '@mui/material/Button'
import { CustomIconFromValue } from '../CustomIconFromValue'
import { Typography, darken, lighten, styled } from '@mui/material'
import { TranslateMessage } from '@app/lib/lang'
import { themeColors } from '@app/constants'
import { ConditionalWrapper } from '../ConditionalWrapper'

export interface CustomButtonProps extends ButtonProps {
	className?: string
	disabled?: boolean
	link?: string
	loading?: boolean
	size?: 'small' | 'medium' | 'large'
	type?: 'button' | 'submit'
	color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
	onClick?: () => void
	label?: string
	fontWeight?: number | string
	fontColor?: string
}

export const CustomButton: FC<CustomButtonProps> = ({
	className,
	children,
	disabled = false,
	link,
	loading = false,
	size = 'large',
	type = 'button',
	color = 'primary',
	onClick,
	label,
	fontWeight = 'bold',
	fontColor,
	sx,
	fullWidth,
	...otherProps
}) => {
	const getCustomButtonSizeClassName = () => {
		switch (size) {
			case 'large':
				return 'large'
			case 'medium':
				return 'medium'
			default:
				return 'small'
		}
	}

	const customButtonSizeClassName = getCustomButtonSizeClassName()

	const getCustomButtonColor = () => {
		switch (color) {
			case 'primary':
				return 'primary'
			case 'secondary':
				return 'secondary'
			case 'error':
				return 'error'

			default:
				return 'small'
		}
	}

	const getCustomButtonColorClassName = getCustomButtonColor()

	const onCustomButtonClick = () => {
		if (onClick) {
			onClick()
		}
	}

	return (
		<ConditionalWrapper
			condition={!!link}
			wrapper={(children) => <Link href={link!}>{children}</Link>}
		>
			<CustomButtonRoot
				disabled={loading || disabled}
				className={
					sx
						? ''
						: classNames([
								'customButton',
								customButtonSizeClassName,
								getCustomButtonColorClassName,
								className,
						  ])
				}
				onClick={onCustomButtonClick}
				variant="contained"
				type={type}
				sx={{
					...sx,
					...(fullWidth && { minWidth: '100%' }),
				}}
				{...otherProps}
			>
				{loading ? (
					<CustomIconFromValue
						icon="svg loader"
						style={{
							height: '100%',
							width: '!00%',
						}}
					/>
				) : (
					<Typography
						component="div"
						fontWeight={fontWeight}
						color={fontColor}
						fontSize={
							size === 'medium' || size === 'small' ? 12 : 14
						}
					>
						{label ? (
							<TranslateMessage id={label ?? ''} />
						) : (
							<>{children}</>
						)}
					</Typography>
				)}
			</CustomButtonRoot>
		</ConditionalWrapper>
	)
}

const CustomButtonRoot = styled(Button)(({ theme, color }) => ({
	borderRadius: '2px',
	boxShadow: 'none',
	'&.customButton': {
		color: 'white',
		borderRadius: '2px',
		boxShadow: 'none',
	},
	'&.small': {
		fontSize: '0.6rem',
	},
	'&.medium': {
		height: '28px',
		minWidth: '145px',
		fontSize: '0.75rem',
	},
	'&.large': {
		height: '48px',
		width: '175px',
		fontSize: '0.75rem',
	},
	'&.primary': {
		backgroundColor: themeColors.dark,
		'&:hover': {
			boxShadow: 'none',
			backgroundColor: lighten(themeColors.dark, 0.12),
		},
	},
	'&.secondary': {
		backgroundColor: themeColors.white,
		border: `2px solid ${themeColors.agitronDark}`,
		color: `${themeColors.agitronDark} !important`,
		'&:hover': {
			boxShadow: 'none',
			backgroundColor: darken(themeColors.white, 0.02),
		},
	},
	'&.error': {
		backgroundColor: themeColors.errorRed,
		'&:hover': {
			boxShadow: 'none',
			backgroundColor: lighten(themeColors.errorRed, 0.12),
		},
	},
}))
