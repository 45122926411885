import { useContext } from 'react'
import { CustomerSettingsContext } from './CustomerSettingsProvider'
import { useCustomerSettingsType } from './types'

export const useCustomerSettings: useCustomerSettingsType = () => {
	const context = useContext(CustomerSettingsContext)

	if (!context) {
		throw new Error(
			'useCustomerSettings must be used within a CustomerSettingsProvider'
		)
	}

	return context
}
