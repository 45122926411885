import { Configuration } from '@azure/msal-browser'

export const msalConfig: Configuration = {
	auth: {
		clientId: process.env.NEXT_PUBLIC_AZURE_CLIENT_ID!,
		// authority: `https://login.microsoftonline.com/${process.env.NEXT_PUBLIC_AZURE_TENANT_ID}`,
		authority: `https://login.microsoftonline.com/common/oauth2/v2.0/authorize`,
		redirectUri:
			typeof window !== 'undefined' ? window.location.origin : undefined,
	},
	cache: {
		cacheLocation: 'sessionStorage',
		storeAuthStateInCookie: false,
	},
}

export const loginRequest = {
	scopes: [
		'User.Read',
		'Calendars.Read',
		'Calendars.Read.Shared',
		'Place.Read.All',
	],
}

export const graphConfig = {
	graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
	graphRoomsEndpoint:
		'https://graph.microsoft.com/v1.0/places/microsoft.graph.room',
	graphCalendarsEndpoint: 'https://graph.microsoft.com/v1.0/me/calendars',
	graphCalendarViewEndpoint:
		'https://graph.microsoft.com/v1.0/me/calendarView',
}
